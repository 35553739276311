<template>
  <el-dialog :title="!id ? '新增' : '修改'" :close-on-click-modal="false" @open="init" :visible.sync="visible" :before-close="handleClose">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="名称" prop="name">
        <el-input v-model="dataForm.name" maxLength="15">
        </el-input>
      </el-form-item>
      <el-form-item label="上级菜单" prop="parentName">

        <el-popover ref="menuListPopover" placement="bottom-start" trigger="click">
          <el-scrollbar style="height:100%">
            <div class="scroll">
              <el-tree :data="menuList" :props="menuListTreeProps" node-key="id" ref="menuListTree" @current-change="menuListTreeCurrentChangeHandle" :default-expand-all="true" :highlight-current="true" :expand-on-click-node="false">
              </el-tree>
            </div>
          </el-scrollbar>
        </el-popover>
        <el-input v-model="dataForm.parentName" v-popover:menuListPopover :readonly="true" placeholder="点击选择上级菜单" class="menu-list__input"></el-input>
      </el-form-item>
      <el-form-item label="所属模块" prop="moduleId">
        <el-select v-model="dataForm.moduleId" placeholder="所属模块" @change="changeModule">
          <el-option v-for="item in modules" :key="item.id" :label="item.moduleName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="授权标识" >
        <el-input v-model="dataForm.permissions" placeholder="多个用逗号分隔, 如: user:list,user:create"></el-input>
      </el-form-item>
      <el-form-item label="排序号" prop="sort">
        <el-input-number v-model="dataForm.sort" controls-position="right" :min="0" label="排序号">
        </el-input-number>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" :loading="btnloading" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {

  props: {
    id: {
      type: String | Number,
      default: "",
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  data() {

    return {
      dataForm: {},
      dataRule: {
        name: [
          {
            required: true,
            message: "菜单名称不能为空",
            trigger: "blur",
          },
        ],
        permissions: [
          {
            required: true,
            message: "授权标识不能为空",
            trigger: "blur",
          },
        ],

      },
      menuList: [],
      menuListTreeProps: {
        label: "name",
        children: "children",
      },
      btnloading: false,
      modules: []
    };
  },
  created() {
    this.init();
    this.getModules();
    this.getMenuList();
  },
  methods: {

    changeModule(val) {
      console.log(val)
      for (var i = 0; i < this.modules.length; i++) {
        if (this.modules[i].id === val) {
          this.dataForm.moduleName = this.modules[i].moduleName;
          break;
        }
      }
    },
    getMenuList() {
      this.$get("/platform-config/community/appmenu/tree")
        .then((data) => {
          this.menuList = data.data;
        })
    },
    getModules() {

      this.$get('/platform-config/community/modulemanagement/list').then(res => {
        this.modules = res.data
      })
    },
    init() {
      this.dataForm = {};
      if (this.id) {
        this.$get(`/platform-config/community/appmenu/${this.id}`).then(
          (res) => {
            this.dataForm = res.data;
            this.menuListTreeSetCurrentNode();
          }
        );
      } else {
        this.dataForm = {
          id: '',
          name: "",
          pid: 0,
          parentName: "",
          permissions: "",
          sort: 0,
          moduleId: '',
          moduleName: ''
        }
        this.menuListTreeSetCurrentNode();
      }


    },
    // 关闭弹窗
    handleClose() {
      this.dataForm.pid = null;
      this.dataForm.parentName = null;
      this.$refs.dataForm.resetFields();
      this.visible = false;
    },
    // 菜单树选中
    menuListTreeCurrentChangeHandle(data, node) {
      this.dataForm.pid = data.id;
      this.dataForm.parentName = data.name;
    },
    // 菜单树设置当前选中节点
    menuListTreeSetCurrentNode() {
      if (this.dataForm.pid && this.dataForm.pid != 0) {
        this.$refs.menuListTree.setCurrentKey(this.dataForm.pid);
        this.dataForm.parentName = (this.$refs.menuListTree.getCurrentNode() ||
          {})["name"];
      }
    },
    // 图标选中
    iconActiveHandle(iconName) {
      this.dataForm.icon = iconName;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid) => {
        if (valid) {
          this.btnloading = true;
          let res;
          if (this.dataForm.id) {
            res = await this.$put("/platform-config/community/appmenu", this.dataForm);
          } else {
            res = await this.$post("/platform-config/community/appmenu", this.dataForm);
          }
          this.btnloading = false;
          if (res && res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.handleClose();
                this.$emit("refreshDataList");
              },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mod-menu {
  .menu-list__input,
  .icon-list__input {
    > .el-input__inner {
      cursor: pointer;
    }
  }

  &__icon-popover {
    width: 458px;
    overflow: hidden;
  }

  &__icon-inner {
    width: 478px;
    max-height: 258px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__icon-list {
    width: 458px;
    padding: 0;
    margin: -8px 0 0 -8px;

    > .el-button {
      padding: 8px;
      margin: 8px 0 0 8px;

      > span {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        font-size: 18px;
      }
    }
  }

  .icon-list__tips {
    font-size: 18px;
    text-align: center;
    color: #e6a23c;
    cursor: pointer;
  }
}
.scroll {
  max-height: 400px;
}
</style>
