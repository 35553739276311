<template>
  <div class="mod-menu">
  
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-button v-if="isAuth('platform-config:menu:save')" type="primary" @click="addOrUpdateHandle('')">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="dataList" row-key="id" border style="width: 100%; ">
      <el-table-column prop="name" header-align="center" min-width="150" label="名称">
      </el-table-column>
      <el-table-column prop="parentName" header-align="center" align="center" width="120" label="上级菜单">
      </el-table-column>
      <el-table-column prop="sort" header-align="center" align="center" label="排序号">
      </el-table-column>
      <el-table-column prop="permissions" header-align="center" align="center" width="150" :show-overflow-tooltip="true" label="授权标识">
      </el-table-column>
      <el-table-column prop="moduleName" header-align="center" align="center" label="所属模块">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
        <template slot-scope="scope">
          <el-button v-if="isAuth('platform-config:menu:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button v-if="isAuth('platform-config:menu:delete')" class="c-danger-imp" type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update :show.sync="show" :id="id" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./menu-add-or-update";
export default {
  data() {
    return {
      dataForm: {},
      dataList: [],
      dataListLoading: false,
      addOrUpdateVisible: false,
      show: false,
      id: ''
    };
  },
  components: {
    AddOrUpdate,
  },
  computed: {

    name() {
      return this.$route.query.name;
    },
  },

  created() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    async getDataList() {
      this.dataListLoading = true;
      let res = await this.$get("/platform-config/community/appmenu/tree", {
        moduleId: this.$route.query.id,
      });
      this.dataListLoading = false;
      if (res && res.code == 0) {
        this.dataList = res.data;
      }
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.id = id || ''
      this.show = true;

    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定执行删除操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$del(`/platform-config/community/appmenu/${id}`).then(
            (res) => {
              if (res && res.code === 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getDataList();
                  },
                });
              } else {
                this.$message.error(res.msg);
              }
            }
          );
        })
        .catch(() => { });
    },
  },
};
</script>
